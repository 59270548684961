.main-wrapper--ftp-instructions {
  height: 100%;
  overflow-y: hidden;
}
.page__inner--ftp-instructions {
  height: 100%;
}
.ftp-instructions {
  &__section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    padding-top: 140px;
    &--top {
      padding: 140px 20px 0 20px;
      @media(max-width: 1024px) {
        padding-top: 40px;
        margin-bottom: 50px;
      }
      @media(max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 110px;
      }
    }
  }
  &__box {
    max-width: 1360px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 70px;
    z-index: 2;
    position: relative;
    @media(max-width:1024px) {
      flex-direction: column-reverse;
      gap: 0;
    }
  }
  &__explanation-box {
    margin-top: 140px;
    min-width: 1360px;
    max-width: 1360px;
    width: 100%;
    z-index: 2;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: #352FEE;
    @media(max-width: 1024px) {
      margin-top: -100px;
      flex-direction: column;
      align-items: center;
      background-color: unset;
    }
    @media(max-width: 768px) {
      max-width: 100%;
      min-width: 100%;
      padding: 0 16px;
      margin-top: -200px;
    }
    &:after {
      content: '';
      width: 1000000px;
      left: -999995px;
      height: 100%;
      background-color: #352FEE;
      position: absolute;
      @media(max-width: 1024px) {
        left: 0;
        top: 100px;
        height: 100000px;
       }
    }
    &:before {
      content: '';
      width: 1000000px;
      right: -1000000px;
      height: 60%;
      background-color: #352FEE;
      position: absolute;
      @media(max-width: 1024px) {
       display: none;
      }
    }
    &--svg {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      @media(max-width: 1024px) {
        position: relative;
        left: unset;
        transform: unset;
        margin-top: -60px;
        z-index: 2;
      }
    }
  }
  &__explanation-cloud {
    min-height: 340px;
    max-width: 652px;
    background-color: #fff;
    border-radius: 20px;
    margin-left: 240px;
    z-index: 2;
    position: relative;
    margin-bottom: 40px;
    padding: 30px 40px;
    @media(max-width: 1024px) {
      margin-left: unset;
      margin-bottom: 0;
    }
    @media(max-width: 768px) {
      max-width: 100%;
    }
  }
  &__explanation-paragraph {
    margin-top: 20px;
    color: var(--Szary-700, #404040);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    span {
      font-weight: 600;
    }
  }
  &__explanation-bottom {
    width: 100%;
    background-color: #352FEE;
    height: 200px;
    @media(max-width: 768px) {
      height: 70px;
    }
  }
  &__explanation-heading {
    color: var(--Szary-700, #404040);
    font-size: 24px;
    font-weight: 900;
    line-height: 30px; 
  }
  &__explanation-list-item {
    color: var(--Szary-700, #404040);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 1px 0;
  }
  &__background {
    position: absolute;
    width: 100%;
    &--explanation {
      left: 0;
      top: 0;
    }
    svg {
      width: 100%;
    }
  }
  &__heading {
    color: var(--Niebieski-500, #352FEE);
    font-size: 36px;
    font-weight: 900;
    line-height: 40px; 
    letter-spacing: -0.36px;
    margin-bottom: 8px;
    @media(max-width: 768px) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  &__description {
    color: var(--Czarny-400, #262626);
    font-size: 48px;
    font-weight: 900;
    line-height: 56px;
    letter-spacing: -0.96px;
    @media(max-width: 768px) {
      font-size: 36px;
      line-height: 40px;
    }
  }
  &__paragraph {
    color: var(--Szary-700, #404040);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 32px;
  }
  &__left {
    max-width: 600px;
    @media(max-width: 1024px) {
      max-width: 100%;
      padding: 0 16px;
    }
  }
  &__right {
    @media(max-width: 1024px) {
      display: flex;
      justify-content: flex-end;
      svg {
        max-height: 200px;
      }
    }
  }
  &__hosting-providers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 8px;
    margin-top: 40px;
    @media(max-width: 1024px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &__hosting-provider {
    grid-column: span 1;
    background-color: #fff;
    border-radius: 6px;
    padding: 8px 16px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      transition: 0.2s;
      color: var(--Niebieski-500, #352FEE);
      svg path{
        stroke: var(--Niebieski-500, #352FEE);;
      }
    }
  }
}

.ftp-single-instruction {
  &__header {
    width: 100%;
    min-height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    &--left {
      display: flex;
      flex-direction: column;
      margin: 97px 0;
      z-index: 2;
      @media(max-width: 768px) {
        margin-bottom: 40px;
      }
    }
  }
  &__header-go-back {
    color: var(--Niebieski-500, #352FEE);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    align-items: center;
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
    cursor: pointer;
  }
  &__header-go-back-icon {
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 16px;
      height: 16px;
      path {
        stroke-width: 3px;
        stroke: var(--Niebieski-500, #352FEE);
      }
    }
  }
  &__header-background {
    svg {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &__header-content {
    max-width: 1360px;
    width: 100%;
    display: flex;
    position: relative;
    padding: 0 20px;
    @media(max-width: 1024px) {
      flex-direction: column;
    }
    @media(max-width: 768px) {
      margin-bottom: 40px;
    }
  }
  &__provider {
    color: var(--Czarny-300, #333);
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 30px;
    margin-top: 8px;
  }
  &__movie {
    z-index: 2;
    border-radius: 16px 0px 0px 16px;
    background: var(--Niebieski-500, #352FEE);
    position: relative;
    max-height: 300px;
    margin: auto 0 auto auto;
    padding: 42px 100px 80px 48px;
    max-width: 600px;
    @media(max-width: 1024px) {
      max-width: 100%;
      width: 100%;
      padding-bottom: 0px;
      padding: 20px 0 20px 20px;
    }
    &:after {
      content: '';
      width:100000px;
      height: 100%;
      right:-100000px;
      background: var(--Niebieski-500, #352FEE);
      position: absolute;
      top: 0;
    }
  }
  &__movie-description {
    @media(max-width: 1024px) {
      max-width: 500px;
      position: relative;
    }
  }
  &__movie-icon {
    position: absolute;
    right: 100px;
    bottom: -110px;
    @media(max-width: 1024px) {
      bottom: unset;
      top: -170px;
      right: -17px;
    }
    svg {
      width: 145px;
    }
  }
  &__movie-heading {
    color: #FFF;
    font-size: 18px;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: -0.045px;
    margin-bottom: 8px;
    @media(max-width: 768px) {
      max-width: 350px;
    } 
  }
  &__movie-description {
    color: var(--Szary-100, #F2F2F2);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 24px;
  }
  &__half-head {
    position: absolute;
    bottom: -200px;
    right: 0;
  }
}

.ftp-instructions-list {
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  &__item {
    max-width: 1370px;
    width: 100%;
    margin: 80px auto 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 40px;
    @media(min-width: 1024px) {
      flex-direction: row;
      gap: 100px;
    }
    &--right img {
      @media(max-width:1024px) {
        max-width: 100%;
      }
    }
  }
  &__item-number {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1000px;
    background: var(--Niebieski-500, #352FEE);
    color: #FFF;
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
    margin-bottom: 16px;
  }
  &__item-heading {
    color: var(--Czarny-300, #333);
    font-size: 18px;
    font-weight: 800;
    line-height: 26px; 
    letter-spacing: -0.045px;
    margin-bottom: 8px;
    a, span {
      color: var(--Niebieski-500, #352FEE);
    }
  }
  &__item-description {
    color: var(--Szary-700, #404040);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; 
    a, span {
      color: var(--Niebieski-500, #352FEE);
    }
    ul {
      li {
        list-style-type: disc;
      }
    }
  }
}