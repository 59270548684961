$blue: #352FEE;
$blue300: #908CFF;
$gray: #666666;
$gray100: #F2F2F2;
$gray300: #BFBFBF;
$gray400: #A6A6A6;
$gray500: #808080;
$gray600: #666;
$green500: #38D58B;
$black: #333;
$separator: #D9D9D9;
$poppins: 'Poppins', sans-serif;
$red500: #E20D0D;

.page-speed {
  width: 100%;
  display:flex !important;
  flex-direction: column;
  @media(min-width: 1024px) {
    flex-direction: unset;
    display: grid !important;
  }
  &__calendar {
    color: #333;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
    text-transform: capitalize;
    svg {
      position: absolute;
      top: 7px;
      right: 6px;
      path {
        fill: $gray500;
      }
    }
  }
  &__calendar-wrapper {
    padding: 8px 38px 8px 14px;
    background-color: $gray100;
    position: relative;
    border-radius: 6px;
    display: flex;
    cursor: pointer;
  }
  &__date-picker {
    border: none;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    .react-datepicker__day--disabled {
      background-color: $gray100 !important;
    }
  }
  &__select .single-website__select__control {
    width: 100%;
  }
  &__chart {
    order:3;
    padding: 0 !important;
    @media(min-width: 1370px) {
      min-height: 419px !important;
      order: unset;
    }
    .single-website__status-top-buttons {
      flex-direction: column;
      align-items: flex-start;
      gap:30px;
      @media(min-width: 1370px) {
        flex-direction: row;
        gap: unset;
        align-items: center;
      }
    }
    .single-website__date-range-wrapper {
      width: 100%;
      @media(min-width: 1370px) {
       width: unset;
       align-content: center;
      }
    }
  }
  &__legend-top {
    display: flex;
    align-items: center;
    color: $gray;
    margin-right: 30px;
    p {
      width: 16px;
      height: 16px;
      background-color: $blue;
      border-radius: 4px;
      margin-right: 8px;
      font-family: $poppins;
      font-weight: 500;
      font-size: 14px;
    }
  }
  &__legend-skeletons {
    display: flex;
    gap: 20px;
    height: 100%;
    width: 50%;
    justify-content: flex-end;
    @media(max-width:1370px) {
      width: 100%;
      justify-content: start;
      flex-direction: column;
      gap: 10px;
    }
    &--legend {
      width: 220px !important;
      height: 30px !important;
      @media(max-width:1370px) {
        width: 100% !important;
        height: 30px !important;
      }
    }
    &--select {
      width: 171px !important;
      height: 40px !important;
      @media(max-width:1370px) {
        width: 100% !important;
        height: 30px !important;
      }
    }
  }
  &__header {
    padding: 20px 24px 0 24px;
    min-height: unset !important; 
    display: flex;
    flex-direction: column;
    @media(min-width:1370px) {
      min-height: 44px !important;
      flex-direction: row;
      justify-content: space-between;
    }
  
  }
  &__header-skeletons {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap:5px;
    &--title {
      width: 200px !important;
      height: 30px !important;
    }
    &--url {
      width: 300px !important;
      height: 20px !important;
    }
  }
  &__header-lock {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  &__bottom-legend-skeleton {
    &__item {
      width: calc(25% - 20px) !important;
      height: 30px !important;
    }
    span {
      width: 100%;
    }
  }
  &__bottom-legend {
    &__list {
      padding: 0px 24px 10px 24px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (min-width: 1370px) {
        flex-wrap: unset;
        padding-top: 10px;
        width: auto;
      }
    }
    &__list-separator {
      width: 1px;
      background-color: $separator;
    }
    &__item {
      width: calc(50% - 1px);
      display: flex;
      gap: 4px;
      font-family: $poppins;
      font-style: normal;
      font-size: 16px;
      line-height: 20px;
      padding: 8px 0;
      align-items: center;
      margin: 10px 0;
      @media (min-width: 1024px) {
        padding: unset;
        align-items: unset;
        width: auto;
      }
      &--text {
          color: $black;
          font-weight: 500;
      }
      &--time {
        font-weight: 700;
        flex-shrink: 0;
      }
    }
  }
}

.performance-summary {
  display: flex;
  column-gap: 16px;
  order: 0;
  grid-column: span 4;
  @media(min-width: 1370px) {
    row-gap: 16px;
    column-gap: unset;
    flex-direction: column;
    order: unset;
    grid-column: span 1;
  }
  .single-website__grid-item {
    @media(min-width: 1370px) {
      min-height: calc(50% - 8px);
    }
  }
  .single-website__grid-item {
    width: 100%;
  }
  &__date {
    color: $gray600;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
    margin-top: -20px;
  }
  &__item {
    font-weight: 700;
    font-family: $poppins;
    &--value {
      font-size: 48px;
    }
    &--time {
      font-size: 40px;
      margin-left: 4px;
    }
  }
  &__corgi-full {
    position: absolute;
    bottom: 0;
    right: 13px;
  }
  &--disabled {
    .single-website__grid-item {
      background-color: $gray100;
      border: 1px solid $gray300;
    }
    .single-website__grid-title {
      color: $gray500
    }
  }
  &__lock-icon {
    position: absolute;
    right: 25px;
    bottom: 25px;
  }
  &__higher-package {
    order: 1;
    max-width: 100%!important;
    @media(min-width: 1370px) {
      order: unset;
      max-width: 192px !important;
    }
    .single-website__bolded-subtitle{
      margin-top: 16px;
      max-width: 200px;
      @media(min-width: 1370px) {
        max-width: unset;
      }
    }
    .single-website__grid-button {
      margin-top: 34px;
    }
    .single-website__grid-item-svg {
      bottom: -30px;
      right: -30px;
      @media(min-width: 1024px) {
        bottom: -40px;
        right: 0;
      }
    }
    h2 {
      max-width: 300px ;
      @media(min-width: 1024px) {
        max-width: 177px ;
      }
    }
    .btn--wrapper {
      max-width: 166px;
      @media(min-width: 1024px) {
        max-width: unset;
      }
    }
  }
  &__higher-package-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    @media(min-width: 1024px) {
      left: 0;
      bottom: -20px;
    }
  }
}

.performance-range {
  .single-website__grid-item {
    min-height: 94px;
  }
  &__item {
    min-height: 70px !important;
  }
  &__skeletons {
    display: flex;
    flex-direction: column;
    &--title-long {
      width: 200px !important;
      height: 20px !important;
      @media(max-width: 1370px){
        width: 75% !important;
      }
    }
    &--title-short {
      width: 120px !important;
      height: 15px !important;
      @media(max-width: 1370px){
        width: 35% !important;
      }
    }
    &--time {
      width: 40px;
      height: 20px;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    min-height: 58px;
    &--left {
      font-style: normal;
      font-weight: 900;
      line-height: 30px;
      min-width: 50px; 
    }
    &--value {
      font-size: 24px;
      color: $green500;
    }
    &--time {
      font-size: 16px;
      margin-left: 4px;
      color: $green500;
    }
    &--center {
      width: 2px;
      background-color: $separator;
      margin: 0 36px;
    }
    &--right {
      margin: auto;
      width: 100%;
      text-transform: lowercase;
      span {
      text-transform: capitalize;
      font-weight: 700;
      }
    }
    &--green {
      color: $green500;
    }
    &--red {
      color: $red500;
    }
    &--gray {
      color: $gray500;
    }
  }
}

.apexcharts-tooltip {
  border: 1px solid transparent !important;
  position: unset !important;
  z-index: 20;
  // transform: translate(-50%, 20px);
}
.page-speed-chart {
  &__tooltip {
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.10) !important;
    width: 85px;
    position: absolute !important;
    padding: 8px;
    background: white;
    border-radius: 4px;
    text-align: center;
    position: relative;
    white-space: nowrap;
    z-index: 99999;
    &::before {
      content: "";
      position: absolute;
      top: -6px; 
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid white;
    }
  }
  &__tooltip-date {
    color: $gray600;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px; 
  }
  &__tooltip-value {
    text-align: left;
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}
.apexcharts-text tspan {
  max-width: 40px !important;
}

.apexcharts-series-markers {
  filter: drop-shadow(0px 2px 12px rgba(56, 50, 236, 0.35));
}
.apexcharts-xaxis-tick {
  display: none;
}
.page-speed {
  title {    
    color: $gray600;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-transform: lowercase;  
  }
  .apexcharts-xaxis-label {
    &:first-child { 
      transform: translateX(12px);
    }
    &:last-child { 
      transform: translateX(-30px);
    }
  }
}

.react-chart {
  position: relative;
  min-height: 303px;
  height: 100%;
  @media(min-width: 1370px) {
    min-height: 375px;
    padding: 0 20px;
  }
  &--loader {
    min-height: 215px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(min-width: 1370px) {
      min-height: 375px;
    }
  }
  .apexcharts-canvas {
    &:after {
      content: '';
      position: absolute;
      top:0;
      right: -7px;
      width:30px;
      height:100%;
      z-index: 1;
      border: 2px solid transparent;
    }
    &:before {
      content: '';
      position: absolute;
      top:0;
      left: -8px;
      width:50px;
      height:100%;
      z-index: 1;
      border: 2px solid transparent;

    }
  }
  
  &__arrow {
    display: none;
    @media(min-width: 1370px) {
      display: block;
    }
    width: 24px;
    height: 24px;
    background-color: $gray100;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    z-index: 2;
    cursor: pointer;
    svg path{
      stroke: $gray500;
    }
  }
  &__mobile-arrows {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    margin: 16px 0 40px;
    padding: 0 24px;
    @media(min-width: 1370px) {
      display: none;
    }
  }
  &__mobile-arrow {
    color: $gray600;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    background: $gray100;
    width: 50%;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
    cursor: pointer;
    svg {
      stroke: #808080;
    }
    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &--previous {
      svg {
        margin-right: auto;
      }
      p {
        margin-right: auto;
      }
    }
    &--next {
      svg {
        margin-left: auto;
        transform: rotate(180deg);
      }
      p {
        margin-left:auto;
      }
    }
    
  }
  &--disabled {
    cursor: not-allowed;
    svg path{
      stroke: $gray500;
      opacity: 0.5;
    }
  }
  &__previous {
    left: 10px;
    transform: translateY(-50%);
  }
  &__next {
    right: 10px;
    transform: translateY(-50%);
    svg {
      transform: rotate(180deg);
    }
  }

  
}


.date-picker-popup-overlay {
  position: absolute;
  top: 50px;
  z-index: 9;
  right: 0;
}

.date-picker-popup {
  .react-datepicker {
    border: 0px;
    border-radius: 10px;
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.14);
    background-color: #fff;
    &__header {
      background-color: #fff;
      border-bottom: unset;
    }
    &__day-name {
      color: $gray400;
      font-size: 14px;
      font-weight: 400;
      line-height: 35px; 
      min-width: 30px;
    }
    &__month-container {
      padding: 8px;
    }
    &__day-names {
      margin-top: 8px;
    }
    &__current-month {
      text-transform: capitalize;
      color:  #111;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px; 
    }
    &__navigation {
      &--next {
        right: 10px;
        top: 10px;
      }
      &--previous {
        left: 10px;
        top: 10px;
      }
      &--next--disabled, &--previous--disabled {
        cursor: not-allowed;
        .react-datepicker__navigation-icon--next:before,
        .react-datepicker__navigation-icon--previous:before {
          border-color: $gray300;
        }
        &:hover {
          .react-datepicker__navigation-icon--next:before,
          .react-datepicker__navigation-icon--previous:before {
            border-color: $gray300;
          }
        }
      }
    }
    &__navigation-icon {
      &--previous, &--next {
        &:before {
          border-color: #808080;
        }
        &:hover:before {
          border-color: #757474;
        }
      }
    }
    
    &__day {
      color:  #111;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px; 
      min-width: 30px;
      transition: 0.2s;
      &:hover {
        background-color: $blue300;
        color: #fff;
        transition: 0.2s;
      }
      &--selected {
        color: #fff;
        background-color: $blue300;
        &:hover {
          background-color: $blue300;
        }
      }
      &--disabled {
        &:hover {
          background-color: unset;
          color: $gray300;
        }
        color: $gray300;
        cursor: not-allowed
      }
    }
  }
}

