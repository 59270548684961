.pricing {
  .blue {
    color: var(--Niebieski-500, #352fee);
  }
  .mainHeading {
    max-width: 556px;
  }
  .section--background {
    z-index: 1;
  }
  .section--who-earns{
    margin-bottom: 0 !important;
  }
  .homepage__earn-most{
    @media (max-width: 1000px) {
      padding-bottom: 60px;
    }
  }

  &__options {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
      gap: 72px;
      margin-top: 20px;
    }
  }
  &__option-wrapper {
    margin-top: -24px;
    padding-top: 24px;
  }
  &__option {
    width: 100%;
    padding: 40px 24px 54px 24px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0px 5px 40px 0px rgba(17, 17, 17, 0.12);
    position: relative;
    max-width: 363px;
    max-height: 100%;
    height: 100%;
    &:after {
      position: absolute;
      content: '';
      top: -24px;
    }
    h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 900;
      line-height: 30px;
      color: #f2f2f2;
    }
    &--basic {
      background: #f2f2f2;
      color: var(--Czarny-500, #404040);
      h2 {
        color: var(--Czarny-500, #333);
      }
      .pricing__option-price {
        color: var(--Czarny-500, #333);
      }
      .btn {
        background: var(--Niebieski-500, #352fee);
        color: #fff;
      }
    }
    &--premium {
      background: var(--Niebieski-500, #352fee);
      color: #f2f2f2;
      .btn {
        color: #fff;
        background: var(--Czarny-400, #262626);
      }
      ul li {
        color: #f2f2f2;
      }
    }
    &--pro {
      background: var(--Czarny-400, #262626);
      color: #f2f2f2;
      .btn {
        color: #fff;
        background: var(--Niebieski-500, #352fee);
      }
      ul li {
        color: #f2f2f2;
      }
    }
    hr {
      width: 100%;
      border-bottom: 0;
      border-top: 0.5px solid #a6a6a6;
      margin: 0;
    }
    .btn {
      margin: 32px 0;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      height: 40px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__option-description {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    margin-top: 8px;
    margin-bottom: 24px;
  }

  &__option-top-icon {
    position: absolute;
    top: -20px;
    left: 24px;
    width: 56px;
    height: 56px;
  }

  &__option-price {
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px; /* 144.444% */
    letter-spacing: -0.36px;
    display: flex;
    align-items: flex-end;
    gap: 6px;
    margin-top: 24px;
    margin-bottom: 4px;
    p {
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
    }
    span {
      color: var(--Szary-500, #808080);
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 2px;
    }
  }
  &__most-chosen-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .homepage__calendry-wrapper {
    padding-top: 200px;
    padding-bottom: 150px !important;
    @media (max-width: 1000px) {
      padding-top: 75px;
    }
  }

  &__most-chosen {
    color: var(--Szary-100, #f2f2f2);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 169.231% */
    background: var(--Niebieski-700, #050099);
    transform: rotate(15deg);
    padding: 4px 51px 4px 100px;
    position: absolute;
    right: -42px;
    top: 12px;
  }
  &__options-section {
    flex-direction: column;
    align-items: center;
  }
  &__dont-know-what-to-choose {
    margin-top: 56px;
    margin-bottom: 24px;
    color: var(--Szary-700, #404040);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    max-width: 310px;
    @media (max-width: 1000px) {
      width: 100%;
      max-width: unset;
    }
  }
  &__option-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 32px;
    li {
      color: var(--Szary-700, #404040);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; 
      display: flex;
      gap: 8px;
      align-items: center;
      position: relative;
      .half {
        width: 50%;
      }
      .itsNew {
        position: absolute;
        top: 0;
        right: -24px;
        height: 100%;
        border-radius: 8px 0px 0px 8px;
        background: var(--Pomaraczowy-500, #F29225);
        display: flex;
        align-items: center;
        padding: 4px 18px;
        color: var(--Czarny-600, #0A0A0A);
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
      }
      .svg {
        height: 24px;
        width: 24px;
        &--close {
          width: 24px;
          height: 12px;
          path {
            stroke: #a6a6a6;
          }
        }
      }
      b {
        font-weight: 700;
      }
      &.inactive {
        color: #a6a6a6;
      }
    }
  }

  &__compare-packages-button {
    border-radius: 30px;
    background: var(--Niebieski-500, #352fee);
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    margin: 0 auto;
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
  &__compare-wrapper {
    @media (max-width: 1000px) {
      width: 100%;
      .btn--wrapper {
        width: 100%;
      }
    }
  }

  &__calculator-wrapper.section {
    z-index: 3;
    margin-top: 200px;
    @media (max-width: 1000px) {
      margin-top: 0px;
      padding-top: 80px;
    }
  }

  &__calculator {
    margin-top: -100px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    z-index: 2;
    @media (max-width: 1000px) {
      margin-bottom: 100px;
    }
    .homepage__subtitle {
      max-width: 556px;
    }
  }

  &__calculator-subtitle {
    margin-top: 32px;
    max-width: 459px;
    color: var(--Szary-700, #404040);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    position: relative;
    b {
      font-weight: 700;
    }
  }

  &__calculator-money {
    position: absolute;
    top: 22px;
    right: 0;
    @media (max-width: 1000px) {
      top: -130px;
      width: 142px;
      height: 107px;
    }
  }

  &__calculator-arrow {
    position: absolute;
    top: 50%;
    left: 110%;
    width: 75px;
    height: 69px;
    transform: rotate(230deg) scaleX(-1);
    path {
      fill: #352fee;
    }
    @media (max-width: 1000px) {
      top: calc(100% + 23px);
      left: 50%;
      transform: rotate(290deg) scaleX(-1) translateY(-50%);
    }
  }

  &__calculator-inputs {
    margin-top: 89px;
  }

  &__calculator-input {
    display: flex;
    gap: 70px;
    margin-bottom: 32px;
    @media (max-width: 1000px) {
      flex-direction: column;
      gap: 24px;
      margin-bottom: 48px;
    }
  }
  &__calculator-text {
    max-width: 220px;
    color: var(--Szary-700, #404040);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }

  &__calculator-corgi {
    display: flex;
    justify-content: flex-end;
    margin-top: 72px;
    gap: 30px;
    b {
      font-weight: 700;
    }
    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  &__calculator-bubble {
    border-radius: 30px;
    background: var(--Szary-100, #f2f2f2);
    padding: 30px 30px 35px 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: var(--Szary-700, #404040);
    position: relative;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    height: fit-content;
    &--earnings {
      max-width: 363px;
      .svg {
        position: absolute;
        top: calc(100% - 20px);
        right: 0;
        @media (max-width: 1000px) {
          right: 60px;
          transform: scaleX(-1);
        }
      }
    }
    &--earn-more {
      max-width: 266px;
      margin-top: 100px;
      .svg {
        position: absolute;
        top: calc(100% - 20px);
        left: 0;
        transform: scaleX(-1);
      }
      @media (max-width: 1000px) {
        .svg {
          top: unset;
          bottom: calc(100% - 20px);
          left: 100px;
          transform: scale(-1);
        }
        margin-top: 50px;
      }
      .btn {
        color: #fff;
        text-align: center;
        font-size: 10px;
        font-weight: 700;
        border-radius: 4px;
        background: var(--Niebieski-500, #352fee);

        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 30px;
      }
    }
  }
  &__calculator-earning {
    border-radius: 10px;
    background: var(--Niebieski-500, #352fee);
    padding: 15px 30px 15px 30px;
    font-size: 36px;
    color: #fff;
    font-style: normal;
    font-weight: 900;
    line-height: 40px; /* 144.444% */
    letter-spacing: -0.36px;
    display: flex;
    align-items: baseline;
    gap: 6px;
    // margin-bottom: 4px;
    width: fit-content;
    p {
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
    }
    span {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
      padding-bottom: 3px;
    }
  }

  &__calculator-corgi-in-suit {
    margin-top: 200px;
    margin-bottom: -200px;
    @media (max-width: 1000px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .homepage__demo {
    .homepage__subtitle {
      color: #fff;
      margin-bottom: 40px;
    }
    .homepage__demo-description {
      color: #fff;
    }
  }

  .section--who-earns {
    .homepage__subtitle {
      color: var(--Czarny-400, #262626);
    }
    .homepage__earn-most-list-item {
      background: var(--Szary-100, #f2f2f2);
      h3 {
        color: var(--Szary-600, #333);
      }
      p {
        color: var(--Szary-600, #666);
      }
      path {
        stroke: #fff;
      }
      rect {
        fill: #352fee;
      }
      .search {
        path {
          stroke: unset;
          fill: #fff;
        }
      }
    }
  }

  &__faq-wrapper {
    padding-bottom: 104px;
    @media (max-width: 1000px) {
      // margin-top: 30px;
      padding-bottom: 0;
    }
    .homepage__subtitle {
      width: 576px;
      margin-bottom: 24px;
      max-width: 100%;
    }
    .homepage__gray {
      height: 116%;
    }
    .homepage__section-content {
      position: relative;
      @media (max-width: 1000px) {
        padding-top: 50px;
      }
    }
  }

  &__faq {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__faq-item {
    border-radius: 6px;
    background: #fff;
    padding: 16px 32px;
    position: relative;
    cursor: pointer;
    &--active {
      cursor: default;
      .pricing__faq-answer {
        max-height: 1000px;
      }
      .pricing__faq-question .svg {
        transform: rotate(180deg);
      }
      .pricing__faq-answer {
        margin-top: 24px;
      }
    }
  }

  &__faq-question {
    color: var(--Szary-700, #404040);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
    .svg {
      position: absolute;
      right: 32px;
      top: 23px;
      height: 10px;
      width: 24px;
      transition: 0.3s;
      .a {
        stroke: #808080;
      }
      @media (max-width: 1000px) {
        right: 16px;
      }
    }
  }

  &__faq-answer {
    color: var(--Szary-600, #666);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    max-width: 814px;
    overflow: hidden;
    max-height: 0;
    transition: 0.3s;
    b {
      font-weight: 700;
    }
  }

  &__faq-background {
    position: absolute;
    right: 0;
    top: -10px;
    width: 171px;
    height: auto;
    @media (max-width: 1000px) {
      width: 112.867px;
      top: -30px;
    }
  }
  &__calculator-section {
    background-color: #f2f2f2;
    margin-top: -50px;
  }

  &__main-image {
    @media (max-width: 1000px) {
      position: absolute;
      right: 0;
      top: 17px;
      .svg {
        height: 104px;
        width: auto;
      }
    }
  }

  &__options-wrapper.section--gray {
    @media (max-width: 1000px) {
      padding-bottom: 150px;
    }
  }
}
